import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFDatePicker({ name, label, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DatePicker
          label={label}
          value={field.value}
          onChange={(value) => setValue(name, value)}
          renderInput={(params) => <TextField {...params} size="small" fullWidth />}
          slotProps={{ textField: { size: 'small' } }}
          {...other}
        />
      )}
    />
  );
}
