// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  Navbar: {
    selfcheckout_subheader: 'Self-Checkout',
    dashboard: 'Dashboard',
    restaurant_products: 'Products',
    menus: 'Menus',
    reports: 'Reports',
    product_tools: 'Products Tools',
    api_sandbox: 'API Sandbox',
    health_subheader: 'Health',
    tools_subheader: 'Tools',
    correction: 'Correction',
    merge: 'Merge',
    edit: 'Edit',
    create: 'Create',
    annotations: 'Annotations',
    admin_subheader: 'Administration',
    users: 'Users',
    list: 'List',
    restaurants: 'Restaurants',
    itentification_token: 'Identification (Token)',
    visualization: 'Visualization',
    reporting: 'Reporting',
    api_documentation: 'API Documentation',
  },
  RestaurantProductList: {
    Restaurant_Product_List_Title: 'Restaurant products',
    Restaurant_Product_List_Heading: 'Restaurant products',
    New_Product: 'Add a product',
    Products_Deleted_Successfully: 'Products deleted successfully!',
    Product_Added_To: 'Products added to the menu of ',
    Product_Name: 'Product Name',
    Cash_Sys_Code: 'Cash System Code',
    Is_Recurrent: 'Recurrent',
    Is_Industrial: 'Industrial',
    Is_Container: 'Container',
    Object_Class: 'Kind',
    TableToolbar: {
      Search_Product: 'Search product...',
    },
    Choose_Date_Menu: 'Choose a date for the menu',
    Add_To_Menu: 'Add to menu',
    Delete_Products: 'Delete products',
    Dense: 'Dense',
    Rows_Per_Page: 'Products per page',
    There_Is_Only: 'Warning, there is only',
    Image: 'image',
    Must_Provide: 'for this product. You must provide at least 5 image and contact Trayvisor.',
    Set_Is_Recurrent: 'Convert to reccurent/no reccurrent product.',
    Cancel: 'Cancel',
    True: 'True',
    False: 'False',
    Product: 'Product',
    Original_Product: 'Original Product',
    Is_Recurrent_Change: 'Recurrent products status changed!',
  },
  RestaurantProductCreate: {
    Restaurant_Product_Create_Title: 'Products: Add a new product',
    Restaurant_Product_Create_Heading_Add: 'Add a new product',
    Restaurant_Product_Create_Heading_Edit: 'Edit a product',
    NewEditForm: {
      Restaurant_Products: 'Products',
      New_Product: 'Add Product',
      Filter_By_Object_Class: 'Filter by object kind',
      Product: 'Product',
      Choose_Product: 'Choose a product',
      Image_Example_Product: 'Image example of the product',
      Product_Required: 'Product is required',
      Product_Name_Required: 'Product name required',
      CashSysCode_Required: 'Cash system code is required',
      Product_Added_Success: 'Product added to your restaurant successfully!',
      Product_Update_Success: 'Update success!',
      Set_Up_Product: 'Set up the product',
      Product_Name: 'Product name',
      CashSysCode: 'Cash system code',
      Is_Recurrent: 'Is recurrent',
      Is_Recurrent_Info: 'Recurrent products will be automatically added to the menu every day.',
      Add_Product: 'Add Product',
      Save_Changes: 'Saves Changes',
      Use_Daily_Products_only: 'Use daily products only',
      Validated_As_Annotation: 'Validated as annotation',
      Ingredients: 'Ingredients',
      No_Selection: 'No selection',
    },
  },
  ProductGeneral: {
    Product_Title: 'Products',
    Name: 'Product Name',
    Label: 'Product Label',
    Description: 'Product Description',
    Object_Class: 'Object Class (detection)',
    Kind: 'Kind',
    Family: 'Family',
    Brand: 'Brand',
    Food_class: 'Food class',
    Is_Industrial: 'Industrial',
    Is_Content: 'Container',
  },
  ProductList: {
    Product_List_Heading: 'Products',
    Product: 'Product',
    List: 'List',
    Add_Product: 'Add a product',
    Merge_Products: 'Merge products',
    TableHead: {
      Id: 'ID',
    },
    TableToolbar: {
      Search_Product: 'Search product...',
    },
    Dense: 'Dense',
    Rows_Per_Page: 'Products per page',
  },
  ProductCreate: {
    Product_Heading_Add: 'Add a new product',
    Product_Heading_Edit: 'Edit a product',
    New_Product: 'New product',
    Set_Up_Product: 'Set up the new product',
    Product_Name_Required: 'Product name required',
    Description_Required: 'Description required',
    Label_Required: 'Label required',
    Is_Indus_Required: 'Industrial required',
    Is_Content_Required: 'Content required',
    Kind_Required: 'Kind required',
    Family_Required: 'Family required',
    Brand_Required: 'Brand required',
    Object_Class_Required: 'Object class required',
    Images_Required: '1 image at least is required',
    Product_Added_Success: 'Product added successfully!',
    Branch_Added_Success: 'Brand added successfully!',
    Food_Class_Added_Success: 'Food class added successfully!',
    Product_Update_Success: 'Update success!',
    Add_Product: 'Add Product',
    Save_Changes: 'Saves Changes',
    Add_Brand: 'Add Brand',
    Add_New_Brand: 'Add New Brand',
    Brand_Name: 'Brand Name',
    Add_Food_Class: 'Add Food Class',
    Add_New_Food_Class: 'Add New Food Class',
    Enrolled_By: 'Enrolled by',
    Is_Industrial_Info: 'Industrials products are the packaged one.',
    Is_Content_Info: 'For the recognition by container (plate).',
    Image_Example_Product: 'Image example',
    NutritionCard: {
      Set_Up_Nutrition_Card: 'Set up the nutrition informations',
      Calcium: 'Calcium',
      Cho: 'CHO (Carbohydrates)',
      Diet_fibre: 'Dietary Fiber',
      Diet_Iron: 'Iron',
      Gross_Weight: 'Gross Weight',
      Kcal: 'Kcal',
      Net_Weight: 'Net Weight',
      Protein: 'Protein',
      Sat_Fat: 'Saturated Fat',
      Sodium: 'Sodium',
      Sugars: 'Sugars',
      Total_Fat: 'Total Fat',
    },
  },
  Products: {
    ClassOptions: {
      Class_All: 'All',
      Class_Indus_Food: 'Industrial',
      Class_Fruit: 'Fruit',
      Class_Drink: 'Drink',
      Class_Dessert_Starter: 'Starter/Dessert',
      Class_Dish: 'Dish',
      Class_Bread: 'Bread',
      Class_Unknown: 'Unknown',
      Class_Others: 'Other',
    },
  },
  Menus: {
    Menus_Title: 'Menus',
    Menus_Header: 'Menus',
    Menu_Date: 'Menu date',
    Menus_Card_Heading: 'Cards',
    Add_Products: 'Add products to menu',
    Delete_From_Menu: 'Delete from menu',
    Display_Menu_History: 'Show menu history',
    Last_Menu_Received_at: 'Last menu received at',
    No_Menu_Received_Today: 'No menu received today',
    Menu_History: 'Menu History:',
  },
  Visualization: {
    Dashboard: 'Dashboard',
    Visualization: 'Visualize',
    Enrollment: 'Enrollment',
    Analysis: 'Analysis',
    No_Enrollment_Found: 'No Enrollment found for',
  },
  Others: {
    All: 'All',
    True: 'Yes',
    False: 'No',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Edit: 'Edit',
  },
  IdentificationToken: {
    Generate_Token_First: 'Please generate token first',
    Token_Copied: 'Token copied to clipboard',
    Identification_Title: 'Identification (Token)',
    Identification_Heading: 'Generate Identification Token',
    Admin: 'Admin',
    Expiration_Time: 'Expiration time (days)',
    Generate_Token: 'Generate Identification Token',
    Click_To_Copy: 'Click to copy',
    Click_To_Download: 'Click to download',
  },
  Selectors: {
    UsersSelector: 'Select user',
  },
  LanguageSelector: {
    Languages: 'Languages',
    en: 'English',
    fr: 'French',
    de: 'German',
  },
  ResetPassword: {
    Password_No_Match: "Password didn't match",
    Password_Too_Short: 'Must be at least 8 characters long',
    Success: 'Success',
    Invalid_Token: 'Invalid token',
    Reset_Password: 'Reset password',
    Password: 'Password',
    Confirm_Password: 'Confirm password',
    Confirm: 'Confirm',
  },
  Login: {
    Invalid_Login: 'Invalid username or password.',
    Successful_Login: 'Successful login',
    Login_Instructions: 'Enter a valid username and password.',
    Username: 'Email',
    Enter_Username: 'Enter username',
    Password: 'Password',
    Enter_Password: 'Enter password',
    Connect: 'Sign In',
    Email_Must_Valid: 'Email must be a valid email address',
    Email_Required: 'Email is required',
    Password_Required: 'Password is required',
    Remember_Me: 'Remember me',
  },
  Reporting: {
    Reporting_Title: 'Reports: Daily',
    Reporting_Heading: 'Daily Reports',
    Reporting: 'Reporting',
    Daily: 'Daily',
    Total_Trays: 'Total trays',
    Total_Tickets: 'Total tickets',
    Wrong_Trays: 'Wrong trays',
    Perc_Of_Good_Trays: '% of good trays (net)',
    Perc_Of_Good_Trays_Live: '% of good trays (gross)',
    Product_Name: 'Product name',
    Object_Class: 'Object Class (detection)',
    Precision: 'Precision',
    Recall: 'Recall',
    F1_Score: 'F1 Score',
    TP_Description: 'True Positive:\nNumber of items correctly identified.',
    FP_Description:
      'False Positive:\nNumber of items incorrectly identified. We predicted that the item was present, but it was not.',
    FN_Description:
      'False Negative:\nNumber of items not identified. We predicted that the item was not present, but it was.',
    Precision_Description: 'Precision: How much of the predicted items are actually present.',
    Recall_Description: 'Recall: How much of the actual items are predicted.',
    F1_Score_Description: 'F1 Score: The harmonic mean of precision and recall.',
    Statistics_Per_Product: 'Statistics per product',
    ObjectClasses_To_Remove: 'Object classes to remove',
  },
  RestaurantList: {
    Restaurant_Title: 'Restaurants',
    Restaurant_Heading_List: 'Restaurants',
    New_Restaurant: 'New restaurant',
    TableHead: {
      Search_Restaurant: 'Search restaurant...',
      Choose_Country: 'Choose country',
      Is_Active: 'Is active',
      Project: 'Project',
    },
    TableRow: {
      Id: 'ID',
      Name: 'Name',
      Alias_Name: 'Alias name',
      Project: 'Project',
      City: 'City',
      Group_Name: 'Group name',
      Cash_Sys_Name: 'Cash system name',
      Country: 'Country',
      Active: 'Active',
      Inactive: 'Inactive',
      Health: 'Health',
      Checkout: 'Checkout',
    },
  },
  RestaurantCreate: {
    Restaurant_Title: 'Restaurants',
    Restaurant_Heading_Add: 'Add a new restaurant',
    Restaurant_Heading_Edit: 'Edit a restaurant',
    Restaurant: 'Restaurant',
    Restaurant_Added_Success: 'Restaurant added successfully!',
    Restaurant_Updated_Success: 'Restaurant updated successfully!',
    FormFields: {
      Name: 'Name',
      Alias_Name: 'Alias name',
      Project: 'Project',
      City: 'City',
      Restaurant_Group: 'Group',
      Cash_System: 'Cash system',
      Address: 'Address',
      Zip_Code: 'Zip/Code',
      Country: 'Country',
      Is_Active: 'Active',
      Health: 'Health',
      Checkout: 'Checkout',
      Authorized_Users: 'Authorized users',
      Create_Restaurant: 'Create restaurant',
      Save_Changes: 'Save changes',
    },
  },
  ApiDocumentation: {
    Copy_Doc: 'Copy OpenAPI doc (JSON)',
    Cash_System_Selector: 'Select a cash system',
    Doc_Copied: 'Documentation copied to clipboard',
  },
  Components: {
    BlockContent: {
      Drop_Or_Select_File: 'Drop or Select file',
      Drop_File_Or_Click: 'Drop files here or click',
      Browse: 'browse',
      Thorough_Your_Machine: 'thorough your machine',
    },
    DeleteDialog: {
      Are_You_Sure: 'Are you sure you want to delete ',
      Product: 'product',
      Action_Cannot_Undone: 'This action cannot be undone.',
    },
    MergeDialog: {
      Are_You_Sure: 'Are you sure you want to merge ',
      Product: 'product',
      Action_Cannot_Undone: 'This action can not be undone !',
      Merge: 'Merge',
    },
  },
};

export default en;
