import { useEffect, useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, ListSubheader, Typography } from '@mui/material';
// hooks
//

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavVersion.propTypes = {};

export default function NavVersion({ ...other }) {
  const { theme } = useTheme();

  const [version, setVersion] = useState('');

  useEffect(() => {
    setVersion(process.env.REACT_APP_VERSION);
  }, []);

  return (
    <Box {...other}>
      <Typography
        variant={'caption'}
        sx={{
          color: 'text.secondary',
          margin: 2,
        }}
      >
        {version}
      </Typography>
    </Box>
  );
}
