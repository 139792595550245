// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  Navbar: {
    selfcheckout_subheader: 'Encaissement',
    dashboard: 'Tableau de bord',
    restaurant_products: 'Produits',
    menus: 'Menus',
    reports: 'Rapports',
    product_tools: 'Outils Produits',
    api_sandbox: 'API Sandbox',
    health_subheader: 'Santé',
    tools_subheader: 'Outils',
    correction: 'Corriger',
    merge: 'Fusionner',
    edit: 'Modifier',
    create: 'Créer',
    annotations: 'Annotations',
    admin_subheader: 'Administration',
    users: 'Utilisateurs',
    list: 'Liste',
    restaurants: 'Restaurants',
    itentification_token: 'Identification (Token)',
    visualization: 'Visualisation',
    api_documentation: 'Documentation API',
  },
  RestaurantProductList: {
    Restaurant_Product_List_Title: 'Produits du restaurant',
    Restaurant_Product_List_Heading: 'Produits du restaurant',
    New_Product: 'Ajouter un produit',
    Products_Deleted_Successfully: 'Produits supprimés avec succès !',
    Product_Added_To: 'Produits ajoutés au menu du ',
    Product_Name: 'Nom du produit',
    Cash_Sys_Code: "Code d'encaissement",
    Is_Recurrent: 'Récurrent',
    Is_Industrial: 'Industriel',
    Is_Container: 'Contenant',
    Object_Class: 'Type',
    TableToolbar: {
      Search_Product: 'Rechercher un produit...',
    },
    Choose_Date_Menu: 'Choisir une date pour le menu',
    Add_To_Menu: 'Ajouter au menu',
    Delete_Products: 'Supprimer les produits',
    Dense: 'Dense',
    Rows_Per_Page: 'Produits par page',
    There_Is_Only: "Attention, il n'y a que",
    Image: 'image',
    Must_Provide: 'pour ce produit. Vous devez fournir au moins 5 images et contacter Trayvisor.',
    Set_Is_Recurrent: 'Set_Is_Recurrent',
    Cancel: 'Annuler',
    True: 'Vrai',
    False: 'Faux',
    Product: 'Produit',
    Original_Product: 'Produit original',
    Is_Recurrent_Change: 'Le status récurrents des produits a été modifié !',
  },
  RestaurantProductCreate: {
    Restaurant_Product_Create_Title: 'Produits : Ajouter un nouveau produit',
    Restaurant_Product_Create_Heading_Add: 'Ajouter un nouveau produit',
    Restaurant_Product_Create_Heading_Edit: 'Modifier un produit',
    NewEditForm: {
      Restaurant_Products: 'Produits',
      New_Product: 'Ajouter un produit',
      Filter_By_Object_Class: "Filtrer par type d'object",
      Product: 'Produit',
      Choose_Product: 'Choisir un produit',
      Image_Example_Product: "Image d'exemple du produit",
      Product_Required: 'Produit requis',
      Product_Name_Required: 'Nom du produit requis',
      CashSysCode_Required: "Code d'encaissement requis",
      Product_Added_Success: 'Produit ajouté à votre restaurant !',
      Product_Update_Success: 'Mise à jour réussie !',
      Set_Up_Product: 'Configurer le produit',
      Product_Name: 'Nom produit',
      CashSysCode: "Code d'encaissement",
      Is_Recurrent: 'Est Récurrent',
      Is_Recurrent_Info: 'Les produits récurrents seront automatiquement ajoutés au menu chaque jour.',
      Add_Product: 'Ajouter le produit',
      Save_Changes: 'Sauvegarder les changements',
      Use_Daily_Products_only: 'Utiliser uniquement les produits du jour',
      Validated_As_Annotation: 'Validé comme annotation',
      Ingredients: 'Ingredients',
      No_Selection: 'Aucune sélection',
    },
  },
  ProductGeneral: {
    Product_Title: 'Produits',
    Name: 'Nom du produit',
    Label: 'Libellé du produit',
    Description: 'Description du produit',
    Object_Class: "Type d'objet (détection)",
    Kind: 'Type',
    Family: 'Famille',
    Brand: 'Marque',
    Food_class: 'Food class',
    Is_Industrial: 'Industriel',
    Is_Content: 'Contenant',
  },
  ProductList: {
    Product_List_Heading: 'Produits',
    Product: 'Produit',
    List: 'Liste',
    Add_Product: 'Ajouter un produit',
    Merge_Products: 'Fusionner des produits',
    TableHead: {
      Id: 'ID',
      Product_Name: 'Nom du produit',
    },
    TableToolbar: {
      Search_Product: 'Rechercher un produit...',
    },
    Dense: 'Dense',
    Rows_Per_Page: 'Produits par page',
  },
  ProductCreate: {
    Product_Heading_Add: 'Ajouter un nouveau produit',
    Product_Heading_Edit: 'Modifier un produit',
    New_Product: 'Nouveau produit',
    Set_Up_Product: 'Configurer le nouveau produit',
    Product_Name_Required: 'Nom du produit requis',
    Description_Required: 'Description du produit requise',
    Label_Required: 'Label du produit requis',
    Is_Indus_Required: 'Industriel requis',
    Is_Content_Required: 'Contenant requis',
    Kind_Required: 'Type requis',
    Family_Required: 'Famille requise',
    Brand_Required: 'Marque requise',
    Object_Class_Required: 'Object class requis',
    Images_Required: '1 image au moins est requise',
    Product_Added_Success: 'Produit ajouté avec succés !',
    Branch_Added_Success: 'Marque ajouté avec succés !',
    Food_Class_Added_Success: 'Food class ajouté avec succés !',
    Product_Update_Success: 'Mise à jour réussi !',
    Add_Product: 'Ajouter le produit',
    Save_Changes: 'Sauvergarder les changements',
    Add_Brand: 'Ajouter une marque',
    Add_New_Brand: 'Ajouter une nouvelle marque',
    Brand_Name: 'Nom de la marque',
    Add_Food_Class: 'Ajouter une food class',
    Add_New_Food_Class: 'Ajouter une nouvelle food class',
    Enrolled_By: 'Enrôlé par',
    Is_Industrial_Info: 'Les produits industriels sont ceux packagés.',
    Is_Content_Info: 'Pour la reconnaissance au contenant.',
    Image_Example_Product: "Image d'exemple",
    NutritionCard: {
      Set_Up_Nutrition_Card: 'Configurer les informations  nutritionnelles',
      Calcium: 'Calcium',
      Cho: 'Glucides',
      Diet_fibre: 'Fibre alimentaire',
      Diet_Iron: 'Fer',
      Gross_Weight: 'Poids brut',
      Kcal: 'Kcal',
      Net_Weight: 'Poids net',
      Protein: 'Protéines',
      Sat_Fat: 'Acides gras saturés',
      Sodium: 'Sodium',
      Sugars: 'Sucre',
      Total_Fat: 'Lipides',
    },
  },
  Products: {
    ClassOptions: {
      Class_All: 'Tous',
      Class_Indus_Food: 'Packagé',
      Class_Fruit: 'Fruit',
      Class_Drink: 'Boisson',
      Class_Dessert_Starter: 'Entrée/Dessert',
      Class_Dish: 'Plat',
      Class_Bread: 'Pain',
      Class_Unknown: 'Inconnu',
      Class_Others: 'Autre',
    },
  },
  Menus: {
    Menus_Title: 'Menus',
    Menus_Header: 'Menus',
    Menu_Date: 'Date du menu',
    Add_Products: 'Ajouter des produits au menu',
    Delete_From_Menu: 'Supprimer du menu',
    Display_Menu_History: "Afficher l'historique des menus",
    Last_Menu_Received_at: 'Dernier menu reçu',
    No_Menu_Received_Today: "Aucun menu reçu aujourd'hui",
    Menu_History: 'Historique des menus :',
  },
  Visualization: {
    Dashboard: 'Tableau de bord',
    Visualization: 'Visualisation',
    Enrollment: 'Enrôlement',
    Analysis: 'Analyse',
    No_Enrollment_Found: 'No Enrollment found for',
  },
  Others: {
    All: 'Tous',
    True: 'Oui',
    False: 'Non',
    Cancel: 'Annuler',
    Delete: 'Supprimer',
    Edit: 'Modifier',
  },
  IdentificationToken: {
    Generate_Token_First: "Veuillez générer un token d'identification",
    Token_Copied: "Token d'identification copié dans le presse-papier",
    Identification_Title: 'Identification (Token)',
    Identification_Heading: "Générer un token d'identification",
    Admin: 'Admin',
    Expiration_Time: "Durée d'expiration (jours)",
    Generate_Token: "Générer un token d'identification",
    Click_To_Copy: 'Cliquez pour copier',
    Click_To_Download: 'Cliquez pour télécharger',
  },
  Selectors: {
    UsersSelector: 'Sélectionner un utilisateur',
  },
  LanguageSelector: {
    Languages: 'Langues',
    en: 'Anglais',
    fr: 'Français',
  },
  ResetPassword: {
    Password_No_Match: 'Les mots de passe ne sont pas identiques',
    Password_Too_Short: 'La mot de passe doit faire au moins 8 caractères',
    Success: 'Succès',
    Invalid_Token: 'Token invalide',
    Reset_Password: 'Réinitialisation du mot de passe',
    Password: 'Mot de passe',
    Confirm_Password: 'Confirmation du mot de passe',
    Confirm: 'Confirmer',
  },
  Login: {
    Invalid_Login: 'Identifiant ou mot de passe incorrect.',
    Successful_Login: 'Connexion réussie',
    Login_Instructions: 'Entrer un indentifiant et un mot de passe valides.',
    Username: 'Email',
    Enter_Username: "Entrer l'identifiant",
    Password: 'Mot de passe',
    Enter_Password: 'Entrer le mot de passe',
    Connect: 'Se connecter',
    Email_Must_Valid: "L'email doit être une adresse email valide",
    Email_Required: "L'email est requis",
    Password_Required: 'Mot de passe requis',
    Remember_Me: 'Se souvenir de moi',
  },
  Reporting: {
    Reporting_Title: 'Rapports : Quotidien',
    Reporting_Heading: 'Rapports',
    Reporting: 'Rapports',
    Daily: 'Quotidien',
    Total_Trays: 'Total des plateaux',
    Total_Tickets: 'Total des tickets',
    Wrong_Trays: 'Plateaux erronés',
    Perc_Of_Good_Trays: '% de plateaux corrects (net)',
    Perc_Of_Good_Trays_Live: '% de plateaux corrects (brut)',
    Product_Name: 'Nom du produit',
    Object_Class: "Type d'objet (détection)",
    Precision: 'Précision',
    Recall: 'Rappel',
    F1_Score: 'Score F1',
    TP_Description: "Vrai Positif :\nNombre d'articles correctement identifiés.",
    FP_Description:
      "Faux Positif :\nNombre d'articles incorrectement identifiés. Nous avons prédit que l'article était présent, mais il ne l'était pas.",
    FN_Description:
      "Faux Négatif :\nNombre d'articles non identifiés. Nous avons prédit que l'article n'était pas présent, mais il l'était.",
    Precision_Description: 'Précision : Proportion des articles prédits qui sont effectivement présents.',
    Recall_Description: 'Rappel : Proportion des articles réels qui sont prédits.',
    F1_Score_Description: 'Score F1 : La moyenne harmonique de la précision et du rappel.',
    Statistics_Per_Product: 'Statistiques par produit',
    ObjectClasses_To_Remove: "Types d'objets à retirer",
  },
  RestaurantList: {
    Restaurant_Title: 'Restaurants',
    Restaurant_Heading_List: 'Restaurants',
    New_Restaurant: 'Nouveau restaurant',
    TableHead: {
      Search_Restaurant: 'Rechercher un restaurant...',
      Choose_Country: 'Choisir un pays',
      Is_Active: 'Actif',
      Project: 'Projet',
    },
    TableRow: {
      Id: 'ID',
      Name: 'Nom',
      Alias_Name: "Nom d'alias",
      Project: 'Projet',
      City: 'Ville',
      Group_Name: 'SRC',
      Cash_Sys_Name: "Système d'encaissement",
      Country: 'Pays',
      Active: 'Actif',
      Inactive: 'Inactif',
      Health: 'Santé',
      Checkout: 'Encaissement',
    },
  },
  RestaurantCreate: {
    Restaurant_Title: 'Restaurants',
    Restaurant_Heading_Add: 'Ajouter un restaurant',
    Restaurant_Heading_Edit: 'Modifier un restaurant',
    Restaurant: 'Restaurant',
    Restaurant_Added_Success: 'Restaurant ajouté avec succès !',
    Restaurant_Updated_Success: 'Restaurant mis à jour avec succès !',
    FormFields: {
      Name: 'Nom',
      Alias_Name: "Nom d'alias",
      Project: 'Projet',
      City: 'Ville',
      Restaurant_Group: 'SRC',
      Cash_System: "Système d'encaissement",
      Address: 'Adresse',
      Zip_Code: 'Code postal',
      Country: 'Pays',
      Is_Active: 'Actif',
      Health: 'Santé',
      Checkout: 'Encaissement',
      Authorized_Users: 'Utilisateurs autorisés',
      Create_Restaurant: 'Créer un restaurant',
      Save_Changes: 'Sauvegarder les changements',
    },
  },
  ApiDocumentation: {
    Copy_Doc: 'Copier la doc OpenAPI (JSON)',
    Cash_System_Selector: "Sélectionner un système d'encaissement",
    Doc_Copied: 'Documentation copiée',
  },
  Components: {
    BlockContent: {
      Drop_Or_Select_File: 'Déposer ou sélectionner un fichier',
      Drop_File_Or_Click: 'Déposez les fichiers ici ou cliquez pour',
      Browse: 'parcourir',
      Thorough_Your_Machine: 'vos fichiers.',
    },
    DeleteDialog: {
      Are_You_Sure: 'Êtes-vous certains de vouloir supprimer ',
      Product: 'produit',
      Action_Cannot_Undone: 'Cette action sera définitive.',
    },
    MergeDialog: {
      Are_You_Sure: 'Êtes-vous certains de vouloir fusionner ',
      Product: 'produit',
      Action_Cannot_Undone: "L'action est irréversible !",
      Merge: 'Fusionner',
    },
  },
};

export default fr;
