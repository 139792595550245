import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// translation
import { useTranslation } from 'react-i18next';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/iconify/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const { i18n, t } = useTranslation('Login');

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('Email_Must_Valid')).required(t('Email_Required')),
    password: Yup.string().required(t('Password_Required')),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  // ----------------------------------------------------------------------

  const SectionStyle = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
  }));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <SectionStyle>
        <Stack spacing={3} minWidth="20vw">
          {!!errors.afterSubmit && <Alert severity="error">{t('Invalid_Login')}</Alert>}
          <RHFTextField name="email" label={t('Username')} sx={{ input: { color: 'black' } }} />

          <RHFTextField
            name="password"
            label={t('Password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ input: { color: 'black' } }}
          />
        </Stack>
      </SectionStyle>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={t('Remember_Me')} />

        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('Connect')}
      </LoadingButton>
    </FormProvider>
  );
}
