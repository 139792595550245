import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const SECONDARY_MAIN = theme.palette.secondary.main;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.1"
        id="icon"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 74 74"
        xmlSpace="preserve"
      >
        <path
          className="st0"
          fill={SECONDARY_MAIN}
          d="M62.9,48.9H10.5c-2,0-4-0.8-5.4-2.2c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0c0.9,0.9,2.2,1.4,3.5,1.4
                h52.4c1.3,0,2.6-0.5,3.5-1.4c0.5-0.5,1.4-0.5,1.9,0c0.5,0.5,0.5,1.4,0,1.9C66.9,48.1,65,48.9,62.9,48.9"
        />
        <path
          className="st1"
          fill={PRIMARY_MAIN}
          d="M42,38.9c-0.3,0-0.7-0.1-1-0.4c-1.1-1.1-2.7-1.8-4.3-1.8c-1.6,0-3.1,0.6-4.3,1.8c-0.5,0.5-1.4,0.5-1.9,0
                c-0.5-0.5-0.5-1.4,0-1.9c1.7-1.7,3.9-2.6,6.2-2.6c2.3,0,4.5,0.9,6.2,2.6c0.5,0.5,0.5,1.4,0,1.9C42.7,38.8,42.3,38.9,42,38.9"
        />
        <path
          className="st1"
          fill={PRIMARY_MAIN}
          d="M47.2,33.7c-0.3,0-0.7-0.1-1-0.4c-2.5-2.5-5.9-3.9-9.5-3.9c-3.6,0-7,1.4-9.5,3.9c-0.5,0.5-1.4,0.5-1.9,0
                c-0.5-0.5-0.5-1.4,0-1.9c3.1-3.1,7.1-4.7,11.4-4.7s8.4,1.7,11.4,4.7c0.5,0.5,0.5,1.4,0,1.9C47.9,33.6,47.6,33.7,47.2,33.7"
        />
        <path
          className="st1"
          fill={PRIMARY_MAIN}
          d="M52.4,28.5c-0.3,0-0.7-0.1-1-0.4c-3.9-3.9-9.2-6.1-14.8-6.1c-5.6,0-10.8,2.2-14.8,6.1c-0.5,0.5-1.4,0.5-1.9,0
                c-0.5-0.5-0.5-1.4,0-1.9c4.5-4.5,10.4-6.9,16.7-6.9c6.3,0,12.2,2.5,16.7,6.9c0.5,0.5,0.5,1.4,0,1.9C53.1,28.3,52.8,28.5,52.4,28.5"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
