import React from 'react';

function TrayvisorFullLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="377.947"
      height="377.947"
      version="1.1"
      viewBox="0 0 377.947 377.947"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath20" clipPathUnits="userSpaceOnUse">
          <path d="M391.863 1211.68v148.31h-47.265v65.59h47.265v78h75.047v-78h57.903v-65.59H466.91v-128.22c0-18.32 9.453-31.9 25.996-31.9 11.231 0 21.867 4.13 26.004 8.85l15.949-57.31c-11.23-10.05-31.32-18.32-62.636-18.32-52.586 0-80.36 27.19-80.36 78.59z" />
        </clipPath>
        <linearGradient
          id="linearGradient26"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1069.29 0 0 -1069.29 328.347 1318.33)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath36" clipPathUnits="userSpaceOnUse">
          <path d="M576.246 1140.18v285.4h75.039v-38.4c20.684 24.81 55.547 45.49 90.996 45.49v-73.27c-5.312 1.18-11.816 1.78-20.672 1.78-24.828 0-57.91-14.19-70.324-32.51v-188.49z" />
        </clipPath>
        <linearGradient
          id="linearGradient42"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(873.43 0 0 -873.43 393.156 1286.43)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath52" clipPathUnits="userSpaceOnUse">
          <path d="M960.34 1208.72v35.46c-12.414 16.55-36.059 25.4-60.274 25.4-29.543 0-53.769-15.95-53.769-43.13 0-27.18 24.226-42.55 53.769-42.55 24.215 0 47.86 8.28 60.274 24.82zm0-68.54v30.13c-19.504-23.62-53.184-37.22-90.41-37.22-45.5 0-98.684 30.73-98.684 94.54 0 67.36 53.184 92.18 98.684 92.18 38.406 0 71.492-12.41 90.41-34.87v36.05c0 28.96-24.824 47.87-62.641 47.87-30.133 0-58.496-11.82-82.136-33.69l-29.54 52.6c34.864 31.31 79.762 44.9 124.676 44.9 65.586 0 125.271-26 125.271-108.13v-184.36z" />
        </clipPath>
        <linearGradient
          id="linearGradient58"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1005.73 0 0 -1005.73 365.663 1282.88)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath68" clipPathUnits="userSpaceOnUse">
          <path d="M1111.04 1095.86c6.51-2.95 16.55-4.72 23.64-4.72 19.5 0 32.5 5.32 39.59 20.68l10.64 24.82-115.82 288.94h80.36l74.45-198.54 75.05 198.54h80.36l-134.14-332.08c-21.27-53.77-59.08-67.95-108.12-69.13-8.27 0-27.78 1.77-36.64 4.72z" />
        </clipPath>
        <linearGradient
          id="linearGradient74"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1005.16 0 0 -1005.16 372.408 1224.98)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath84" clipPathUnits="userSpaceOnUse">
          <path d="M1496.33 1140.18l-118.78 285.4h48.46l94.54-233.99 95.14 233.99h47.86l-118.77-285.4z" />
        </clipPath>
        <linearGradient
          id="linearGradient90"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1056.75 0 0 -1056.75 320.721 1282.88)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath100" clipPathUnits="userSpaceOnUse">
          <path d="M1709.66 1140.18v285.4h44.33v-285.4zm-7.67 355.72c0 16.55 13.58 30.14 30.14 30.14 16.54 0 29.54-13.59 29.54-30.14s-13-29.54-29.54-29.54c-16.56 0-30.14 12.99-30.14 29.54z" />
        </clipPath>
        <linearGradient
          id="linearGradient106"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(666.632 0 0 -666.632 847.25 1333.11)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath116" clipPathUnits="userSpaceOnUse">
          <path d="M1818.4 1178l22.45 31.91c18.91-21.87 54.96-40.78 93.96-40.78 43.72 0 67.95 20.69 67.95 49.05 0 69.14-176.68 26.59-176.68 132.36 0 44.91 37.81 82.13 105.77 82.13 49.04 0 83.31-18.31 105.77-41.36l-20.68-30.73c-17.14 20.68-47.86 36.04-85.09 36.04-39 0-63.22-19.49-63.22-44.9 0-62.04 176.68-21.27 176.68-132.36 0-47.87-38.41-86.27-111.68-86.27-47.28 0-86.27 14.78-115.23 44.91z" />
        </clipPath>
        <linearGradient
          id="linearGradient122"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(980.162 0 0 -980.162 456.633 1282.88)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath132" clipPathUnits="userSpaceOnUse">
          <path d="M2336.64 1283.18c0 57.91-33.68 109.9-94.55 109.9-60.85 0-94.54-51.99-94.54-109.9 0-58.5 33.69-110.5 94.54-110.5 60.87 0 94.55 52 94.55 110.5zm-235.77 0c0 82.72 54.36 149.49 141.22 149.49s141.23-66.77 141.23-149.49c0-82.72-54.37-150.09-141.23-150.09-86.86 0-141.22 67.37-141.22 150.09z" />
        </clipPath>
        <linearGradient
          id="linearGradient138"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1047.07 0 0 -1047.07 355.147 1282.88)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clipPath148" clipPathUnits="userSpaceOnUse">
          <path d="M2455.44 1140.18v285.4h44.31v-45.49c23.05 29.54 56.14 51.99 95.14 51.99v-45.49c-5.32.59-10.64 1.18-17.14 1.18-27.77 0-65-22.46-78-45.5v-202.09z" />
        </clipPath>
        <linearGradient
          id="linearGradient154"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(845.806 0 0 -845.806 774.069 1286.13)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#29397e" stopOpacity="1" />
          <stop offset="1" stopColor="#41bc6e" stopOpacity="1" />
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 377.947)">
        <g transform="scale(.1)">
          <g>
            <g clipPath="url(#clipPath20)">
              <path
                fill="url(#linearGradient26)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M391.863 1211.68v148.31h-47.265v65.59h47.265v78h75.047v-78h57.903v-65.59H466.91v-128.22c0-18.32 9.453-31.9 25.996-31.9 11.231 0 21.867 4.13 26.004 8.85l15.949-57.31c-11.23-10.05-31.32-18.32-62.636-18.32-52.586 0-80.36 27.19-80.36 78.59"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath36)">
              <path
                fill="url(#linearGradient42)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M576.246 1140.18v285.4h75.039v-38.4c20.684 24.81 55.547 45.49 90.996 45.49v-73.27c-5.312 1.18-11.816 1.78-20.672 1.78-24.828 0-57.91-14.19-70.324-32.51v-188.49h-75.039"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath52)">
              <path
                fill="url(#linearGradient58)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M960.34 1208.72v35.46c-12.414 16.55-36.059 25.4-60.274 25.4-29.543 0-53.769-15.95-53.769-43.13 0-27.18 24.226-42.55 53.769-42.55 24.215 0 47.86 8.28 60.274 24.82zm0-68.54v30.13c-19.504-23.62-53.184-37.22-90.41-37.22-45.5 0-98.684 30.73-98.684 94.54 0 67.36 53.184 92.18 98.684 92.18 38.406 0 71.492-12.41 90.41-34.87v36.05c0 28.96-24.824 47.87-62.641 47.87-30.133 0-58.496-11.82-82.136-33.69l-29.54 52.6c34.864 31.31 79.762 44.9 124.676 44.9 65.586 0 125.271-26 125.271-108.13v-184.36h-75.63"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath68)">
              <path
                fill="url(#linearGradient74)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M1111.04 1095.86c6.51-2.95 16.55-4.72 23.64-4.72 19.5 0 32.5 5.32 39.59 20.68l10.64 24.82-115.82 288.94h80.36l74.45-198.54 75.05 198.54h80.36l-134.14-332.08c-21.27-53.77-59.08-67.95-108.12-69.13-8.27 0-27.78 1.77-36.64 4.72l10.63 66.77"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath84)">
              <path
                fill="url(#linearGradient90)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M1496.33 1140.18l-118.78 285.4h48.46l94.54-233.99 95.14 233.99h47.86l-118.77-285.4h-48.45"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath100)">
              <path
                fill="url(#linearGradient106)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M1709.66 1140.18v285.4h44.33v-285.4zm-7.67 355.72c0 16.55 13.58 30.14 30.14 30.14 16.54 0 29.54-13.59 29.54-30.14s-13-29.54-29.54-29.54c-16.56 0-30.14 12.99-30.14 29.54"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath116)">
              <path
                fill="url(#linearGradient122)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M1818.4 1178l22.45 31.91c18.91-21.87 54.96-40.78 93.96-40.78 43.72 0 67.95 20.69 67.95 49.05 0 69.14-176.68 26.59-176.68 132.36 0 44.91 37.81 82.13 105.77 82.13 49.04 0 83.31-18.31 105.77-41.36l-20.68-30.73c-17.14 20.68-47.86 36.04-85.09 36.04-39 0-63.22-19.49-63.22-44.9 0-62.04 176.68-21.27 176.68-132.36 0-47.87-38.41-86.27-111.68-86.27-47.28 0-86.27 14.78-115.23 44.91"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath132)">
              <path
                fill="url(#linearGradient138)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M2336.64 1283.18c0 57.91-33.68 109.9-94.55 109.9-60.85 0-94.54-51.99-94.54-109.9 0-58.5 33.69-110.5 94.54-110.5 60.87 0 94.55 52 94.55 110.5zm-235.77 0c0 82.72 54.36 149.49 141.22 149.49s141.23-66.77 141.23-149.49c0-82.72-54.37-150.09-141.23-150.09-86.86 0-141.22 67.37-141.22 150.09"
              />
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath148)">
              <path
                fill="url(#linearGradient154)"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M2455.44 1140.18v285.4h44.31v-45.49c23.05 29.54 56.14 51.99 95.14 51.99v-45.49c-5.32.59-10.64 1.18-17.14 1.18-27.77 0-65-22.46-78-45.5v-202.09h-44.31"
              />
            </g>
          </g>
          <path fill="#29397e" fillOpacity="1" fillRule="nonzero" stroke="none" d="M1622.33 1836.34" />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1702.85 1635.13h-583.4c-22.72 0-44.05 8.84-60.11 24.9-5.86 5.86-5.86 15.35 0 21.21 5.86 5.86 15.35 5.86 21.21 0 10.39-10.38 24.2-16.11 38.9-16.11h583.4c14.69 0 28.49 5.73 38.88 16.11 5.86 5.86 15.36 5.86 21.22 0 5.85-5.86 5.85-15.35 0-21.21-16.06-16.06-37.4-24.9-60.1-24.9"
          />
          <path fill="#29397e" fillOpacity="1" fillRule="nonzero" stroke="none" d="M1246.72 1836.34" />
          <path
            fill="#41bc6e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1469.45 1745.59c-3.84 0-7.68 1.47-10.61 4.4-12.74 12.74-29.67 19.75-47.69 19.75-18.02 0-34.96-7.01-47.71-19.75-5.86-5.86-15.35-5.86-21.21 0-5.86 5.86-5.86 15.35 0 21.21 18.41 18.41 42.88 28.54 68.92 28.54 26.02 0 50.5-10.13 68.9-28.54 5.86-5.86 5.86-15.35 0-21.21-2.92-2.93-6.76-4.4-10.6-4.4"
          />
          <path
            fill="#41bc6e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1527.76 1803.89c-3.84 0-7.68 1.47-10.61 4.4-28.32 28.32-65.96 43.91-106 43.91-40.04 0-77.69-15.59-106.01-43.91-5.86-5.86-15.35-5.86-21.21 0-5.86 5.86-5.86 15.35 0 21.21 33.99 33.98 79.17 52.7 127.22 52.7s93.23-18.72 127.22-52.7c5.85-5.86 5.85-15.35 0-21.21-2.93-2.93-6.77-4.4-10.61-4.4"
          />
          <path
            fill="#41bc6e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1586.06 1862.2c-3.84 0-7.67 1.47-10.6 4.4-43.89 43.88-102.24 68.06-164.31 68.06-62.07 0-120.43-24.18-164.32-68.06-5.86-5.86-15.35-5.86-21.21 0-5.86 5.86-5.86 15.35 0 21.21 49.55 49.55 115.44 76.85 185.53 76.85 70.08 0 135.97-27.3 185.52-76.85 5.86-5.86 5.86-15.35 0-21.21-2.93-2.93-6.77-4.4-10.61-4.4"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1385.96 1018.33l-8.96 23.91c-1.17 3.03-2.36 6.74-3.6 11.14-.78-3.38-1.88-7.09-3.33-11.14l-9.07-23.91zm12.41-32.631l-9.6 24.551h-30.94l-9.5-24.551h-9.09l30.51 77.501h7.56l30.35-77.501h-9.29"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1423.69 985.699v77.181h8.97v-77.181h-8.97"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1501.71 985.699h-8.98v77.181h43.03v-7.97h-34.05v-28.25h31.99v-7.97h-31.99v-32.991"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1559.42 1024.4c0-10.46 2.23-18.39 6.69-23.79 4.45-5.399 10.92-8.098 19.4-8.098 8.55 0 15.01 2.687 19.38 8.068 4.35 5.39 6.54 13.32 6.54 23.82 0 10.38-2.18 18.25-6.52 23.62-4.35 5.37-10.77 8.05-19.3 8.05-8.55 0-15.05-2.69-19.5-8.1-4.46-5.4-6.69-13.27-6.69-23.57zm61.51 0c0-12.36-3.12-22.07-9.37-29.15-6.25-7.07-14.92-10.609-26.05-10.609-11.37 0-20.14 3.468-26.32 10.429-6.18 6.95-9.27 16.76-9.27 29.43 0 12.57 3.1 22.3 9.3 29.23 6.2 6.91 14.99 10.37 26.39 10.37 11.1 0 19.75-3.52 25.98-10.56 6.23-7.04 9.34-16.75 9.34-29.14"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1652.54 1025.51h12.29c6.34 0 10.99 1.25 13.94 3.76 2.95 2.52 4.43 6.29 4.43 11.34 0 5.1-1.49 8.77-4.51 11.03-3.01 2.25-7.83 3.38-14.48 3.38h-11.67zm0-7.71v-32.101h-8.98v77.181h21.16c9.48 0 16.48-1.81 20.99-5.43 4.52-3.63 6.79-9.09 6.79-16.37 0-10.21-5.18-17.11-15.52-20.7l20.96-34.681h-10.62l-18.68 32.101h-16.1"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1758.28 985.699h-8.99v77.181h43.04v-7.97h-34.05v-28.25h31.98v-7.97h-31.98v-32.991"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1815.99 1024.4c0-10.46 2.23-18.39 6.68-23.79 4.46-5.399 10.92-8.098 19.41-8.098 8.55 0 15.01 2.687 19.37 8.068 4.37 5.39 6.54 13.32 6.54 23.82 0 10.38-2.17 18.25-6.51 23.62-4.35 5.37-10.78 8.05-19.31 8.05-8.54 0-15.04-2.69-19.5-8.1-4.45-5.4-6.68-13.27-6.68-23.57zm61.52 0c0-12.36-3.14-22.07-9.38-29.15-6.26-7.07-14.93-10.609-26.05-10.609-11.38 0-20.15 3.468-26.32 10.429-6.17 6.95-9.27 16.76-9.27 29.43 0 12.57 3.11 22.3 9.29 29.23 6.2 6.91 15 10.37 26.39 10.37 11.1 0 19.75-3.52 25.99-10.56 6.22-7.04 9.35-16.75 9.35-29.14"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1905.6 1024.4c0-10.46 2.23-18.39 6.69-23.79 4.45-5.399 10.91-8.098 19.4-8.098 8.55 0 15.02 2.687 19.37 8.068 4.37 5.39 6.54 13.32 6.54 23.82 0 10.38-2.16 18.25-6.51 23.62-4.36 5.37-10.78 8.05-19.31 8.05-8.54 0-15.04-2.69-19.49-8.1-4.46-5.4-6.69-13.27-6.69-23.57zm61.52 0c0-12.36-3.14-22.07-9.38-29.15-6.25-7.07-14.93-10.609-26.05-10.609-11.37 0-20.14 3.468-26.32 10.429-6.17 6.95-9.27 16.76-9.27 29.43 0 12.57 3.11 22.3 9.3 29.23 6.19 6.91 14.99 10.37 26.38 10.37 11.11 0 19.75-3.52 25.99-10.56 6.22-7.04 9.35-16.75 9.35-29.14"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2041.83 1024.71c0 10.07-2.52 17.65-7.58 22.75-5.04 5.11-12.55 7.66-22.51 7.66h-13.03v-61.659h10.92c10.7 0 18.74 2.629 24.14 7.889 5.38 5.26 8.06 13.05 8.06 23.36zm9.5.32c0-12.74-3.45-22.48-10.37-29.229-6.91-6.742-16.86-10.102-29.85-10.102h-21.38v77.181h23.66c11.99 0 21.32-3.33 27.97-9.98 6.65-6.65 9.97-15.94 9.97-27.87"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2117.52 1006.24c0-6.799-2.47-12.099-7.4-15.9-4.92-3.801-11.61-5.699-20.06-5.699-9.15 0-16.19 1.179-21.12 3.539v8.66c3.18-1.34 6.62-2.399 10.35-3.172a54.27 54.27 0 0111.09-1.156c5.98 0 10.49 1.129 13.52 3.41 3.02 2.269 4.54 5.428 4.54 9.468 0 2.67-.54 4.87-1.61 6.58-1.07 1.7-2.87 3.27-5.39 4.72-2.52 1.44-6.35 3.08-11.47 4.9-7.19 2.57-12.32 5.62-15.39 9.14-3.09 3.53-4.63 8.11-4.63 13.77 0 5.96 2.23 10.69 6.71 14.21 4.46 3.52 10.38 5.28 17.74 5.28 7.68 0 14.72-1.4 21.16-4.22l-2.79-7.81c-6.38 2.67-12.57 4.01-18.58 4.01-4.76 0-8.47-1.02-11.15-3.07-2.66-2.03-4.01-4.88-4.01-8.49 0-2.68.49-4.87 1.47-6.58.99-1.7 2.66-3.27 5-4.7 2.34-1.42 5.92-3 10.75-4.72 8.08-2.89 13.66-5.98 16.7-9.3 3.05-3.3 4.57-7.59 4.57-12.87"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2181.73 985.699h-43.02v77.181h43.02v-7.97h-34.04v-24.86h31.98v-7.92h-31.98v-28.411h34.04v-8.02"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2213.23 1025.51h12.29c6.34 0 10.99 1.25 13.94 3.76 2.96 2.52 4.43 6.29 4.43 11.34 0 5.1-1.49 8.77-4.51 11.03-3.01 2.25-7.83 3.38-14.49 3.38h-11.66zm0-7.71v-32.101h-8.98v77.181h21.17c9.47 0 16.47-1.81 20.98-5.43 4.52-3.63 6.79-9.09 6.79-16.37 0-10.21-5.18-17.11-15.52-20.7l20.96-34.681h-10.62l-18.68 32.101h-16.1"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2320.57 1062.88h9.66l-27.82-77.181h-8.87l-27.66 77.181h9.5l17.74-49.94c2.04-5.74 3.65-11.32 4.85-16.741a137.406 137.406 0 004.98 17.061l17.62 49.62"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2346.26 985.699v77.181h8.97v-77.181h-8.97"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2414.86 1055.97c-8.48 0-15.18-2.83-20.09-8.48-4.91-5.64-7.36-13.38-7.36-23.2 0-10.1 2.36-17.91 7.1-23.42 4.73-5.499 11.48-8.261 20.24-8.261 5.38 0 11.53.969 18.43 2.911v-7.872c-5.35-2.007-11.95-3.007-19.79-3.007-11.38 0-20.15 3.449-26.33 10.347-6.17 6.902-9.27 16.702-9.27 29.412 0 7.96 1.49 14.92 4.46 20.9 2.98 5.98 7.27 10.6 12.89 13.84 5.61 3.23 12.23 4.85 19.83 4.85 8.08 0 15.16-1.48 21.22-4.44l-3.8-7.71c-5.85 2.76-11.69 4.13-17.53 4.13"
          />
          <path
            fill="#29397e"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2498.45 985.699h-43.02v77.181h43.02v-7.97h-34.05v-24.86h31.99v-7.92h-31.99v-28.411h34.05v-8.02"
          />
        </g>
      </g>
    </svg>
  );
}

export default TrayvisorFullLogo;
