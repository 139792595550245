import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, createFilterOptions, Grow, Paper, Stack, TextField } from '@mui/material';
import useRestaurants from '../../../hooks/useRestaurants';

// ----------------------------------------------------------------------

const DEFAULT_INPUT_WIDTH = 90;

function PaperComponent(paperProps, ref) {
  return (
    <Grow in>
      <Paper {...paperProps} ref={ref} />
    </Grow>
  );
}

RestaurantSelector.propTypes = {
  onlyActiveRestaurants: PropTypes.bool,
};

export default function RestaurantSelector({ onlyActiveRestaurants = false }) {
  // Get theme in order to get font size
  const theme = useTheme();

  const { restaurant, restaurantList, setRestaurant } = useRestaurants();

  // Set the width of the input
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  const [filteredRestaurant, setFilteredRestaurant] = useState(restaurantList);

  // -------------------------------------------------------------------------------------------------------------------
  // Filter the options to only get the active restaurants
  useEffect(() => {
    if (onlyActiveRestaurants) {
      setFilteredRestaurant(restaurantList.filter((restaurant) => restaurant.is_active === true));
      // Check if the selected restaurant is active, if not, select the first active restaurant
      console.log(
        'restaurants',
        restaurantList.find((opt) => opt.id === restaurant),
        restaurant
      );
      if (restaurant && !restaurantList.find((opt) => opt.id === restaurant)?.is_active) {
        console.log(
          'Selected restaurant is not active',
          restaurantList.find((opt) => opt.id === restaurant)
        );
        // Get the first active restaurant
        const firstActiveRestaurant = restaurantList.find((opt) => opt.is_active === true);
        if (firstActiveRestaurant) {
          setRestaurant(firstActiveRestaurant.id);
        }
      }
    } else {
      setFilteredRestaurant(restaurantList);
    }
  }, [onlyActiveRestaurants, restaurant, restaurantList, setRestaurant]);

  // Set the width of the input
  useEffect(() => {
    // Get the text value of the selected restaurant
    const textValue = restaurantList.find((opt) => opt.id === restaurant)?.name || '';

    // Get the width of the text
    const textWidth = getTextWidth(textValue, theme.typography.body1);

    // Set the width of the input
    setInputWidth(textWidth + 10);
  }, [restaurant, restaurantList]);
  // -------------------------------------------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------------------------------------------
  // Function to get the width of a text
  function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = `${font.fontSize} ${font.fontFamily}`;
    return context.measureText(text).width;
  }

  // Function to filter the options
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name + option.group_name,
  });
  // -------------------------------------------------------------------------------------------------------------------

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <Autocomplete
        id="restaurant-selector"
        size={'small'}
        disableClearable
        // Options sorted by group_name and then by name
        options={filteredRestaurant.sort((a, b) => {
          if (a.group_name === b.group_name) {
            return a.name.localeCompare(b.name);
          }
          return a.group_name.localeCompare(b.group_name);
        })}
        // Custom filter on name and group_name
        filterOptions={filterOptions}
        // Enable grow animation
        PaperComponent={forwardRef(PaperComponent)}
        // Set the width of the list
        componentsProps={{ popper: { style: { width: 'max-content' } } }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={restaurantList.find((opt) => opt.id === restaurant) || null}
        groupBy={(option) => option.group_name}
        onChange={(event, newValue) => {
          setRestaurant(newValue.id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Restaurant"
            size={'small'}
            inputProps={{
              ...params.inputProps,
              style: { textTransform: 'capitalize', width: inputWidth },
            }}
          />
        )}
        // List of options
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            key={option.id}
            value={option.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
              ...(restaurant === option.id && {
                backgroundColor: theme.palette.action.selected,
                '&[data-focus="true"]': {
                  backgroundColor: theme.palette.action.selected,
                },
              }),
            }}
          >
            {option.name}
          </Box>
        )}
      />
    </Stack>
  );
}
