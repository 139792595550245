// @mui
import { styled } from '@mui/material/styles';
import { CardActionArea, Grid, RadioGroup } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../iconify/Iconify';
import BoxMask from './BoxMask';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingDense() {
  const { themeDense, onToggleDense } = useSettings();

  console.log('themeDense', themeDense);

  return (
    <RadioGroup name="themeDense" value={themeDense} onChange={onToggleDense}>
      <Grid dir="ltr" container spacing={2.5}>
        {[false, true].map((dense, index) => {
          const isSelected = themeDense === dense;

          return (
            <Grid key={dense} item xs={6}>
              <BoxStyle
                sx={{
                  ...(isSelected && {
                    color: 'primary.main',
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                }}
              >
                <Iconify
                  icon={
                    index === 0
                      ? 'fluent:align-space-between-vertical-20-filled'
                      : 'fluent:align-space-evenly-vertical-20-filled'
                  }
                  width={28}
                  height={28}
                />
                <BoxMask value={dense} />
              </BoxStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
