// @mui
import { styled } from '@mui/material/styles';
import { Card, Container } from '@mui/material';
// translation
import { useTranslation } from 'react-i18next';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';
// sections
import { LoginForm } from '../../sections/auth/login';
import BigLogo from '../../components/BigLogo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: 'white',
    color: 'black',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  backgroundColor: '#F0FFFF',
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, user } = useAuth();
  const { i18n, t } = useTranslation('Login');

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  console.log(method);
  console.log(user);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          <LanguagePopover />
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <BigLogo />
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
