import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import { cssStyles } from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// hooks
import useRestaurants from '../../../hooks/useRestaurants';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/iconify/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { RestaurantSelector } from '../../../sections/@dashboard/restaurantSelector/index';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

const PAGE_RESTAURANT_SELECTOR = [
  PATH_DASHBOARD.app,
  PATH_DASHBOARD.restaurantProduct.root,
  PATH_DASHBOARD.menus.root,
  PATH_DASHBOARD.visualization.root,
  PATH_DASHBOARD.reporting.root,
  PATH_DASHBOARD.health_visualization.root,
];

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { restaurant, restaurantList } = useRestaurants();
  const isDesktop = useResponsive('up', 'lg');

  // In order to check if we display the restaurant selector, let's get the pathname
  const { pathname } = useLocation();

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {restaurantList.length > 1 && restaurant !== undefined && displayRestaurantSelector(pathname) && (
            <RestaurantSelector onlyActiveRestaurants />
          )}
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function displayRestaurantSelector(pathname) {
  pathname = pathname.replace(`${PATH_DASHBOARD}/`, '').split('/')[2];
  return PAGE_RESTAURANT_SELECTOR.map((path) => path.replace(`${PATH_DASHBOARD}/`, '').split('/')[2]).includes(
    pathname
  );
}
